import React from "react"
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Container } from 'reactstrap';
import { Link } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';

const Blog = styled(Container)`
  margin-top: 64px;
  h1 {
    ${props => props.theme.main.fonts.displayLarge}
    margin-bottom: 32px;
    ${breakpoint('md')`
      margin-bottom: 64px;
    `}
  }
`;

const BlogPosts = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const BlogPost = styled(Link)`
  flex: 0 0 100%;
  margin-bottom: 32px;
  &:hover {
    text-decoration: none;
  }
  h3 {
    ${props => props.theme.main.fonts.displaySmall}
    margin-bottom: 8px;
  }
  .author {
    ${props => props.theme.main.fonts.body}
    color: ${props => props.theme.main.colors.grey.slate};
    font-weight: bold;
  }
  .date {
    ${props => props.theme.main.fonts.body}
    color: ${props => props.theme.main.colors.grey.darker};
  }
  .gatsby-image-wrapper {
    margin-bottom: 8px;
  }
  ${breakpoint('md')`
    flex: 0 0 calc(100% / 3 - 20px);
    margin-bottom: 48px;
    &:first-of-type {
      flex: 0 0 100%;
      margin-bottom: 56px;
      .gatsby-image-wrapper {
        width: 50%;
        margin-right: 30px;
      }
      display: flex;
    }
    &:nth-child(3n+3) {
      margin-left: 30px;
      margin-right: 30px;
    }
  `}
`;

export default ({data}) => {
  return (
    <Blog>
      <h1>Calico Blog</h1>
      <BlogPosts>
        {data.allContentfulBlogPost.nodes.map((blogPost, i) => (
          <BlogPost key={i} to={`/blog/${blogPost.slug}`}>
            <Img fluid={blogPost.image.fluid} />
            <div>
              <h3>{blogPost.title}</h3>
              <div className="author">By: {blogPost.author.name}</div>
              <div className="date">{blogPost.date}</div>
            </div>
          </BlogPost>
        ))}
      </BlogPosts>
    </Blog>);
}
